WiseMetering.Views.GlobalDashboard = Backbone.Marionette.Layout.extend({
    className: 'dashboard full-height',
    currentIndex: 0,
    mapWidget: null,
    selectedUtilityKind: null,
    template: 'templates/ui/global_dashboard',
    userPreferences: [],
    utilityKinds: null,
    widgetSize: { height: 300, gridSize: 'half' },
    widgets: [],

    events: {
        'click .utility': 'onChangeUtility'
    },

    regions: {
        totalDisplayer: '#total-displayer'
    },

    initialize: function(){
        this.selectedUtilityKind = { name: 'All', slug: 'all' };
        this.userPreferences = WiseMetering.userPreferences.dashboardWidgetsDefault();
        this.utilityKinds = WiseMetering.utilityKinds.dashboardFormat();
    },

    buildConsumptionChart: function(options) {
        const
            normalization = false,
            period = options['period'],
            title = i18next.t('common.consumption').capitalize(),
            unit = this.selectedUtilityKind.unit,
            useNormalization = true;

        let data = WiseMetering.Analytics.buildingsConsumption(options['period'], options['building_ids'], this.selectedUtilityKind.slug),
            widget = null;

        if (data.length) {
            if (data.length > 100) {
                data = data.slice(0, 100);
            }

            widget = new WiseMetering.Views.Ui.Widget.BarChart({
                data,
                el: '#widgets-wrapper',
                period,
                title,
                useNormalization,
                normalization,
                unit,
                widgetSize: this.widgetSize
            });
        } else {
            widget = this.buildEmptyWidget(title);
        }

        this.widgets.push(widget);
    },

    buildDistributionChart: function(options) {
        const
            title = i18next.t('glossary.distribution').capitalize(),
            unit = this.selectedUtilityKind.unit,
            useArea = options['use_area'];

        let data = WiseMetering.Analytics.buildingsConsumption(options['period'], options['building_ids'], this.selectedUtilityKind.slug),
            widget = null;

        if (data.length) {
            widget = new WiseMetering.Views.Ui.Widget.PieChart({
                data,
                el: '#widgets-wrapper',
                useArea,
                title,
                unit,
                widgetSize: this.widgetSize
            });
        } else {
            widget = this.buildEmptyWidget(title);
        }

        this.widgets.push(widget);
    },

    buildEmptyWidget: function(title, message) {
        return new WiseMetering.Views.Ui.Widget.NoData({
            el: '#widgets-wrapper',
            message,
            title,
            widgetSize: this.widgetSize
        });
    },

    buildEventsWidget: function() {
        this.poller = setInterval(() => this.fetchEvents(moment.tz().subtract(7, 'days').format('YYYY.MM.DD'), moment.tz().format('YYYY.MM.DD')), 900000);
        this.tableWidget = this.createEventsTable(WiseMetering.Analytics.getLatestEvents(this.selectedUtilityKind.slug));

        this.listenTo(WiseMetering.dashboardEvents, 'add change remove', function() {
            this.tableWidget.close();
            this.tableWidget = this.createEventsTable(WiseMetering.Analytics.getLatestEvents(this.selectedUtilityKind.slug));
            WiseMetering.layout.showTipper('info', i18next.t('widgets.events.events_refresh'));
            this.tableWidget.render();
        }.bind(this));
    },

    buildHeader: function() {
        const
            widgetParams = {
                el: '#global-dashboard-header',
                selectedUtilityKind: this.selectedUtilityKind,
                utilities: this.userPreferences
            },
            widget = new WiseMetering.Views.Ui.GlobalDashboardHeader(widgetParams);

        this.widgets.push(widget);
    },

    buildMapWidget: function() {
        const
            collection = WiseMetering.zones.activeSites(),
            widget = new WiseMetering.Views.Ui.Widget.DashboardMap({ collection });

        widget.render(false);
        widget.build();
        widget.delegateEvents();
    },

    buildObjectiveAnalisys: function(options) {
        const analysisTypes = this.selectedUtilityKind.slug === 'all' ? ['cost'] : ['consumption', 'cost'];

        analysisTypes.forEach(function(type) {
            const
                unit = type === 'cost' ? null : this.selectedUtilityKind.unit,
                title = i18next.t('widgets.title.' + 'objectives_' + type),
                data = WiseMetering.Analytics.getObjectiveAnalysisByUtility(this.selectedUtilityKind.slug, unit);

            if (_.isEmpty(data)) {
                widget = this.buildEmptyWidget(title, i18next.t('widgets.no_data_cta.objectives'));
            } else {
                const
                    now = moment(),
                    currentDay = now.date(),
                    currentMonth = now.month(),
                    daysInMonth = now.daysInMonth(),
                    currentValue = data['real'].slice(0, currentMonth + 1).reduce((acc, num) => acc + num, 0),
                    highligthArea = {};

                let currentGoal = data['objective'].slice(0, currentMonth + 1).reduce((acc, amount, month) => {
                    if (amount && month === currentMonth) {
                        amount = amount * currentDay / daysInMonth;
                    }
                    return acc + amount;
                }, 0);

                if (unit && unit.toLowerCase() === 'wh') {
                    currentGoal = currentGoal * 1000
                }

                let percentageValue = (currentValue - currentGoal) / currentGoal * 100;

                highligthArea['value'] = this.formatPercentageValue(percentageValue);
                highligthArea['textColor'] = 'white';

                if (percentageValue <= 0) {
                    highligthArea['icon'] = 'arrowDown';
                    highligthArea['backgroundColor'] = WiseMetering.SemaphorColors.green;
                } else {
                    highligthArea['icon'] = 'arrowUp';
                    highligthArea['backgroundColor'] = WiseMetering.SemaphorColors.red;
                }

                widget = new WiseMetering.Views.Ui.Widget.GlobalObjectives({
                    data,
                    el: '#widgets-wrapper',
                    title,
                    unit,
                    highlightArea: highligthArea,
                    widgetSize: this.widgetSize
                });
            }

            this.widgets.push(widget);
        }.bind(this));
    },

    buildOpportunitiesWidget: function(options) {
        const title = i18next.t('glossary.opportunities').capitalize(),
              useArea = options['use_area'];

        let data = opportunitiesByUtility = WiseMetering.Analytics.getUtilityOpportunities(this.selectedUtilityKind.slug),
            widget = null;

        if (data.length) {
            widget = new WiseMetering.Views.Ui.Widget.PieChart({
                data,
                el: '#widgets-wrapper',
                useArea,
                title,
                unit: "€",
                widgetSize: this.widgetSize
            });
        } else {
            widget = this.buildEmptyWidget(title, i18next.t('widgets.no_data_cta.opportunities'));
        }

        this.widgets.push(widget);
    },

    buildCo2Chart: function(options) {
        if (this.selectedUtilityKind.slug != 'all') return;

        const title = `${i18next.t('widgets.title.co2').capitalize()} (βeta version)`,
              useArea = options['use_area'];

        let data = co2ByBuildings = WiseMetering.Analytics.getBuildingsCo2(),
            widget = null;

        if (data.length) {
            widget = new WiseMetering.Views.Ui.Widget.PieChart({
                data,
                el: '#widgets-wrapper',
                useArea,
                title,
                unit: "g",
                widgetSize: this.widgetSize
            });
        } else {
            widget = this.buildEmptyWidget(title, i18next.t('widgets.no_data_cta.opportunities'));
        }

        this.widgets.push(widget);
    },

    buildTotalCostByUtilityKind: function(options) {
        const title = i18next.t('widgets.title.total_cost_by_utility');

        let data = WiseMetering.Analytics.totalCostByUtility(options['building_ids']),
            widget = null;

        if (data.length) {
            widget = new WiseMetering.Views.Ui.Widget.PieChart({
                data,
                el: '#widgets-wrapper',
                title,
                unit: WiseMetering.getCurrency(),
                widgetSize: this.widgetSize
            });
        } else {
            widget = this.buildEmptyWidget(title);
        }

        this.widgets.push(widget);
    },

    buildTotalCostsPerSqM: function(options) {
        const
            axis = {
                x: { labelUnit: 'm²', title: i18next.t('glossary.square_meters').capitalize()},
                y: { labelUnit: WiseMetering.currentOrganization.get('currency'), title: i18next.t('common.cost').capitalize() }
            },
            title = i18next.t('widgets.title.cost_analysis');

        let data = WiseMetering.Analytics.getTotalCostsPerSqM(moment().year(), this.selectedUtilityKind['slug']),
            widget = null;

        if (data.length) {
            widget = new WiseMetering.Views.Ui.Widget.BubbleChart({
                axis: axis,
                data,
                el: '#widgets-wrapper',
                title,
                widgetSize: this.widgetSize
            });
        } else {
            widget = this.buildEmptyWidget(title);
        }

        this.widgets.push(widget);
    },

    buildTotalizers: function() {
        const totalizers = this.getTotalizers();

        totalizers.forEach(totalizer => {
            const widgetParams = {
                bgColor: '#8BC53F',
                el: '#total-displayers',
                icon: totalizer.icon,
                legend: totalizer.legend,
                mainColor: '#224C4F',
                percentageBar: totalizer.percentageBar,
                smallIcon: totalizer.smallIcon,
                subtitle: totalizer.subtitle,
                title: totalizer.title,
                value: totalizer.value
            };

            const widget = new WiseMetering.Views.Ui.Widget.NumberDisplay(widgetParams);

            this.widgets.push(widget);
        });
    },

    buildWidgets: function() {
        this.widgets = [];
        // these preferences will be fetched from API in the future
        const widgetPreferences = this.userPreferences[this.selectedUtilityKind.slug]

        this.buildHeader();
        this.buildTotalizers();

        widgetPreferences.forEach(options => {
            switch (options.type) {
                case 'consumption':
                    this.buildConsumptionChart(options);
                    break;
                case 'co2':
                    this.buildCo2Chart(options);
                    break;
                case 'distribution':
                    this.buildDistributionChart(options);
                    break;
                case 'events':
                    this.buildEventsWidget(options);
                    break;
                case 'opportunities':
                    this.buildOpportunitiesWidget(options);
                    break;
                case 'totalCostPerSqM':
                    this.buildTotalCostsPerSqM(options);
                    break;
                case 'totalCostByUtility':
                    this.buildTotalCostByUtilityKind(options);
                    break;
                case 'objectiveAnalisys':
                    this.buildObjectiveAnalisys(options);
                    break;
                default:
                    console.error('Unknown widget type - ' + options.type);
            }
        });
    },

    closeWidgets: function() {
        this.widgets.forEach(widget => widget.close());
        this.tableWidget.close();
    },

    createEventsTable(collection) {
        const title = i18next.t('widgets.title.last_events');

        let widget = null;

        if (collection.length){
            widget = new WiseMetering.Views.Ui.Widget.Table({
                id: 'events',
                collection: collection,
                el: '#widgets-wrapper',
                height: this.widgetsHeight,
                title: title,
                modelSerializer: model => {
                    const building = model.building();
                    return {
                        icon: WiseMetering.icons.getIconFromKind(model.indicator().get('kind_slug')),
                        deviation: this.eventDeviation(model),
                        location: { main: building.get('name'), sub: model.indicator().get('name'), width: '2' },
                        time: this.eventTime(model, building),
                        day: this.eventDate(model, building)
                    };
                }
            });
        } else {
             const
                alarms = WiseMetering.Analytics.getAlarmsByUtilityKind(this.selectedUtilityKind.slug).length,
                message = alarms === 0 ? i18next.t('widgets.no_data_cta.alarms_empty') : i18next.t('widgets.no_data_cta.alarms_good') + '</b>' + i18next.t('widgets.no_data_cta.alarms_note');

            widget = this.buildEmptyWidget(title, message);
        }

        return widget;
    },

    eventDate: function(model, building) {
        const
            main = WiseMetering.utils.formatDate(model.get('start'), building.get('timezone'), 'MMM D'),
            sub = WiseMetering.utils.formatDate(model.get('start'), building.get('timezone'), 'YYYY');

        return { main: main, sub: sub, text_align: 'right', width: '1' };
    },

    eventDeviation: function(model) {
        const
            maxValue = model.get('max_value'),
            minValue = model.get('min_value'),
            unit = model.indicator().get('unit'),
            value = model.get('value');

        let deviation = 0, limit = '', main = '', sub = '';

        if (value > maxValue) {
            deviation = value - maxValue;
            main = formatValue(deviation, unit) + ' ' + i18next.t('widgets.events.above_maximum');
            limit = 'Max: ' + formatValue(maxValue, unit);
        } else if (value < minValue) {
            deviation = minValue - value;
            main = formatValue(deviation, unit) + ' ' + i18next.t('widgets.events.bellow_minimum');
            limit = 'Min: ' + formatValue(minValue, unit);
        } else {
            return i18next.t('widgets.events.within_range');
        }

        sub = `${model.alarm().get('name')} | ${limit}`;

        return { main, sub, width: '3' };
    },

    eventTime: function(model, building) {
        const
            start = WiseMetering.utils.formatDate(model.get('start'), building.get('timezone'), 'HH:mm'),
            finish = WiseMetering.utils.formatDate(model.get('finish'), building.get('timezone'), 'HH:mm'),
            main = `${start} - ${finish}`,
            sub = i18next.t('widgets.events.event_period').capitalize();

        return { main, sub, width: '1.25' };
    },

    fetchEvents(from, to) {
        return WiseMetering.dashboardEvents.fetch({
            data: {
                from: from,
                to: to,
                limit: 5
            }
        });
    },

    formatPercentageValue: function(percentageValue) {
        let absoluteValue = Math.abs(percentageValue);
        return absoluteValue >= 1000 ? '+999 %' : formatValue(absoluteValue, "%");
    },

    getTotalizers: function() {
        const utilitySlug = this.selectedUtilityKind.slug,
              utilityUnit = this.selectedUtilityKind.unit;

        let totalizers = [];

        if (utilitySlug != 'all') {
            const sites = WiseMetering.Analytics.getBuildingsWithUtility(utilitySlug),
                  utilityCoverage =  formatValue((100 * sites.length) / WiseMetering.zones.activeSites().length, 'N/A', 0);

            totalizers = [
                {
                    legend: utilityCoverage + '% ' + i18next.t('widgets.totalizers.utility_coverage') + " " + i18next.t(`utility_kinds.${utilitySlug}`),
                    smallIcon: 'buildings',
                    title: i18next.t('glossary.sites').capitalize(),
                    value: WiseMetering.Analytics.getBuildingsWithUtility(utilitySlug).length
                },
                {
                    smallIcon: 'indicators',
                    title: i18next.t('widgets.totalizers.all_indicators'),
                    value: WiseMetering.indicators.indicatorsByUtilityKind(utilitySlug).length
                },
                {
                    legend: i18next.t('periods.ytd'),
                    smallIcon: 'dolar',
                    title: i18next.t('widgets.totalizers.total_cost'),
                    value: WiseMetering.Analytics.getTotalUtilityExpenditure(utilitySlug)
                },
                {
                    legend: i18next.t('glossary.savings') + " " + i18next.t('periods.per.year'),
                    smallIcon: 'savings',
                    title: i18next.t(`glossary.opportunities`).capitalize(),
                    value: WiseMetering.Analytics.getOpportunitiesTotalByUtility(utilitySlug)
                },
                {
                    legend: i18next.t('periods.ytd'),
                    smallIcon: WiseMetering.icons.getIconFromKind(utilitySlug),
                    title: i18next.t('widgets.totalizers.total_consumption'),
                    value: WiseMetering.Analytics.getTotalConsumption(utilitySlug, utilityUnit)
                },
                {
                    legend: i18next.t('widgets.totalizers.active_alarms'),
                    smallIcon: 'alarms',
                    title: i18next.t('glossary.alarms').capitalize(),
                    value: WiseMetering.Analytics.getAlarmsByUtilityKind(utilitySlug).length
                }
            ];
        } else {
            const activeObjectives = WiseMetering.Analytics.getObjectivesCompliance();

            totalizers = [
                {
                    smallIcon: 'buildings',
                    title: i18next.t('glossary.sites').capitalize(),
                    value: WiseMetering.zones.activeSites().length
                },
                {
                    smallIcon: 'indicators',
                    title: i18next.t('widgets.totalizers.all_indicators'),
                    value: WiseMetering.indicators.length
                },
                {
                    legend: i18next.t('periods.ytd'),
                    smallIcon: 'dolar',
                    title: i18next.t('widgets.totalizers.total_cost').capitalize(),
                    value: WiseMetering.Analytics.getTotalExpenditure()
                },
                {
                    legend: i18next.t('glossary.savings') + " " + i18next.t('periods.per.year'),
                    smallIcon: 'savings',
                    title: i18next.t('glossary.opportunities').capitalize(),
                    value: WiseMetering.Analytics.getOpportunitiesTotal()
                },
                {
                    legend: activeObjectives['total'] + " " + i18next.t('widgets.totalizers.active_objectives'),
                    smallIcon: 'indicators',
                    percentageBar: true,
                    title: i18next.t('widgets.totalizers.year_objectives'),
                    value: activeObjectives['percentage']
                },
                {
                    legend: i18next.t('widgets.totalizers.active_alarms'),
                    smallIcon: 'alarms',
                    title: i18next.t('glossary.alarms').capitalize(),
                    value: WiseMetering.alarms.activeAlarms().length
                },
            ];
        }

        return totalizers;
    },

    onChangeUtility: function(event) {
        const newUtilityKind = event.currentTarget;

        if ( newUtilityKind.getAttribute('value') === 'all' ){
            this.selectedUtilityKind = { name: 'All', slug: 'all' };
        } else {
            this.selectedUtilityKind = this.utilityKinds.find(utility => utility.slug === newUtilityKind.getAttribute('value'));
        }

        this.reRender();
    },

    onClose: function() {
        this.closeWidgets();

        if (this.poller) {
            clearInterval(this.poller);
        }
    },

    onRender: function() {
    },

    onShow: function() {
        this.buildMapWidget();
        this.buildWidgets();
        this.renderWidgets();
        this.tableWidget.render();
    },

    renderWidgets: function() {
        this.widgets.forEach(widget => {
            widget.render(false);
            widget.build();
            widget.delegateEvents();
        });
    },

    reRender: function() {
        this.closeWidgets();
        this.buildWidgets();
        this.renderWidgets();
        this.tableWidget.render();
    },
});
