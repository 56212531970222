WiseMetering.controller = {
    // helper functions first
    expandNodes: function(model) {
        if (typeof model?.parent !== 'function') {
            return;
        }

        const parents = [];

        let parent = model.parent();
        while (parent) {
            parents.push(parent);
            parent = parent.parent();
        }

        parents.reverse().forEach(parent => parent.trigger('node:expand'));
    },
    // end of helper functions

    createOrganization: function() {
        return new WiseMetering.Views.CreateOrganization({model: new WiseMetering.Model.Organization});
    },

    editOrganization: function() {
        return new WiseMetering.Views.EditOrganization({ model: WiseMetering.currentOrganization });
    },

    drawDefault: function() {
        Backbone.history.navigate('/home', { trigger: true });
    },

    drawView: view => WiseMetering.layout.content.show(view),

    drawMap: function() {
        const collection = WiseMetering.zones.activeSites();
        this.drawView(new WiseMetering.Views.Map({ collection }));
    },

    drawAdmin: function() {
        this.drawView(new WiseMetering.Views.Admin());
    },

    // All indexes are in one line, because it's always the same code and this way improves readability

    drawActuators: function() {
        this.drawView(new WiseMetering.Views.IndexActuator);
    },

    drawAlarms: function() {
        this.drawView(new WiseMetering.Views.AlarmTabs);
    },

    drawApiKeys: function() {
        const viewName = WiseMetering.currentOrganization.showIntegrations() ? 'IndexApiKeys' : 'NoAccess';
        this.drawView(new WiseMetering.Views[viewName]);
    },

    drawBuilding: function(id, view) {
        if (view.indexOf('?') !== -1) {
            [view] = view.split('?');
        }

        const model = WiseMetering.zones.get(id);

        if (model) {
            this.expandNodes(model);
            model.trigger('node:select');
            this.drawView(new WiseMetering.Views.Zone({ model, view }));
        } else {
            this.drawDefault();
        }
    },

    drawBuildingTags: function() {
        this.drawView(new WiseMetering.Views.IndexBuildingTags);
    },

    drawCircuit: function(id) {
        const model = WiseMetering.circuits.get(id);
        if (model) {
            this.expandNodes(model);
            model.trigger('node:select');
            this.drawView(new WiseMetering.Views.Circuit({ model }));
        } else {
            this.drawDefault();
        }
    },

    drawCircuits: function() {
        this.drawView(new WiseMetering.Views.Circuits);
    },

    drawCircuitTags: function() {
        this.drawView(new WiseMetering.Views.IndexCircuitTags);
    },

    drawContract: function(id, view) {
        const model = WiseMetering.contracts.get(id);

        if (model) {
            this.expandNodes(model);
            model.trigger('node:select');
            this.drawView(new WiseMetering.Views.Contract({ model, view }));
        } else {
            this.drawDefault();
        }
    },

    drawCompanies: function() {
        const viewName = WiseMetering.currentOrganization.hasAdvancedPermissions() ? 'IndexCompany' : 'NoAccess';
        this.drawView(new WiseMetering.Views[viewName]);
    },

    drawDashboard: function() {
        this.drawView(new WiseMetering.Views.GlobalDashboard());
    },

    drawDevices: function() {
        this.drawView(new WiseMetering.Views.IndexDevice);
    },

    drawEvents: function() {
        this.drawView(new WiseMetering.Views.IndexEvent);
    },

    drawEtls: function() {
        this.drawView(new WiseMetering.Views.IndexEtl);
    },

    drawFolder: function(id) {
        const model = WiseMetering.folders.get(id);

        if (model) {
            this.expandNodes(model);
            model.trigger('node:select');
            this.drawView(new WiseMetering.Views.Folder({ model }));
        } else {
            this.drawDefault();
        }
    },

    drawGlobalIndicators: function() {
        this.drawView(new WiseMetering.Views.IndexGlobalIndicators);
    },

    drawGroups: function() {
        this.drawView(new WiseMetering.Views.IndexGroup);
    },

    drawHolidays: function() {
        this.drawView(new WiseMetering.Views.IndexHoliday);
    },

    drawIndicators: function() {
        this.drawView(new WiseMetering.Views.IndicatorsTabs);
    },

    drawIndicator: function(id, view) {
        const model = WiseMetering.indicators.get(id);

        if (!model) {
            this.drawDefault();
            return;
        }

        let validTabs = ['details', 'plot', 'objectives', 'integration_assets'];
        if (model.get('metals') && WiseMetering.currentUser.get('manager')) {
            validTabs.push('data');
        }
        if (!view) {
            view = 'plot';
        }

        if (validTabs.includes(view)) {
            this.expandNodes(model);
            model.trigger('node:select');
            this.drawView(new WiseMetering.Views.Indicator({ model, view }));
        } else {
            this.drawDefault();
        }
    },

    drawIntegrations: function(id) {
        const partner = WiseMetering.integrationPartners.get(id);
        this.drawView(new WiseMetering.Views[`Index${partner.getClassName()}Integration`](id));
    },

    drawIntegrationPartners: function() {
        this.drawView(new WiseMetering.Views.IndexIntegrationPartners);
    },

    drawLogin: function() {
        if (WiseMetering.currentUser) {
            this.drawDefault();
        } else {
            WiseMetering.layout = new WiseMetering.Views.Layouts.Login;
            WiseMetering.mainRegion.show(WiseMetering.layout);
        }
    },

    drawMessages: function() {
        this.drawView(new WiseMetering.Views.IndexMessages);
    },

    drawMarkers: function() {
        this.drawView(new WiseMetering.Views.MarkersTabs);
    },

    drawOrganization: function(id) {
        const model = WiseMetering.currentOrganization;
        this.drawView(new WiseMetering.Views.OrganizationTabs({ model }));
    },

    drawReadings: function() {
        this.drawView(new WiseMetering.Views.IndexReading);
    },

    drawReports: function() {
        this.drawView(new WiseMetering.Views.ReportTabs);
    },

    drawSeu: function(id) {
        const model = WiseMetering.seus.get(id);
        if (model) {
            this.expandNodes(model);
            model.trigger('node:select');
            this.drawView(new WiseMetering.Views.Seu({ model }));
        } else {
            this.drawDefault();
        }
    },

    drawTariffs: function() {
        const viewName = WiseMetering.currentOrganization.hasStandardPermissions() ? 'TarrifsTabs' : 'NoAccess';
        this.drawView(new WiseMetering.Views[viewName])
    },

    drawTenants: function() {
        const viewName = WiseMetering.currentOrganization.hasAdvancedPermissions() ? 'ShowTenantsByOrganization' : 'NoAccess';
        this.drawView(new WiseMetering.Views[viewName]);
    },

    drawTenant: function(id) {
        const model = WiseMetering.tenants.get(id);
        if (model) {
            this.expandNodes(model);
            model.trigger('node:select');
            this.drawView(new WiseMetering.Views.Tenant({ model }));
        } else {
            this.drawDefault();
        }
    },

    drawRootObject: function(id, view) {
        const model = WiseMetering.rootObjects.get(id);

        if (model) {
            this.expandNodes(model);
            model.trigger('node:select');

            if (model.get('slug') === 'utilities') {
                this.drawView(new WiseMetering.Views.UtilitiesRootObject({ model, view }));
            } else {
                this.drawView(new WiseMetering.Views.RootObject({ model }));
            }
        } else {
            this.drawDefault();
        }
    },

    drawTree: function() {
        const buildingId = window.MapService.buildingIds.at(0);

        if (buildingId) {
            Backbone.history.navigate(WiseMetering.zones.get(buildingId).nodeUrl(), { replace: true, trigger: true });
        } else {
            WiseMetering.layout.showTipper('error', 'No buildings available', 5000);
            Backbone.history.navigate('/', { replace: true, trigger: true });
        }
    },

    drawUsers: function() {
        this.drawView(new WiseMetering.Views.IndexUser);
    },

    drawUtility: function(id, view) {
        if (!view) {
            view = 'details';
        }
        if (view.indexOf('?') !== -1) {
            [view] = view.split('?');
        }

        const model = WiseMetering.utilities.get(id);
        if (model) {
            this.expandNodes(model);
            model.trigger('node:select');
            this.drawView(new WiseMetering.Views.Utility({ model, view }));
        } else {
            this.drawDefault();
        }
    },

    drawZoneAttributes: function() {
        this.drawView(new WiseMetering.Views.IndexZoneAttributes);
    },

    drawZone: function(id, view) {
        if (!view) {
            view = 'dashboard';
        }
        if (view.indexOf('?') !== -1) {
            [view] = view.split('?');
        }

        const model = WiseMetering.zones.get(id);
        if (model) {
            this.expandNodes(model);
            model.trigger('node:select');
            this.drawView(new WiseMetering.Views.Zone({ model, view }));
        } else {
            this.drawDefault();
        }
    }

};
